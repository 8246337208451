import { MavoVehicle } from '../interfaces/Vehicle';
import Referentials from './Referentials.json';

export const RoleClaimsEnum = {
  Administrator: 'Administrator',
  Admin: 'ADMIN',
  AdminUsers: 'ADMIN_USERS',
  AdminValueList: 'ADMIN_VALUELISTS',
  AdminOrganization: 'ADMIN_ORGANIZATION',
  SAMPLEPAGEV3: 'SAMPLEPAGEV3',
  Agenda: 'AGENDA',
  Dashboard: 'DASHBOARD',
  Evaluation: 'EVALUATION',
  NewCase: 'NEW_CASE',
  Home: 'HOME',
  Case: 'CASE',
  Order: 'ORDER',
  Vehicle: 'VEHICLE',
  Settings: 'SETTINGS',
  ManagerExport: 'MANAGER_EXPORT',
  MavoVehicle: 'MAVO_VEHICLE',
  MavoAgent: 'MAVO_AGENT',
  MavoAdministrator: 'MAVO_ADMINISTRATOR',
  MavoContab: 'MAVO_CONTAB',
  AdminCountryOrganization: 'ADMIN_COUNTRY_ORGANIZATION',
  AdminService: 'ADMIN_SERVICE',
  VehicleSearch: 'VEHICLE_SEARCH'
};

export const OrganizationTypeCode = {
  GT: 'GT',
  SUPPLIER: 'FRZ',
  LEASING: 'LEA',
  REP: 'REP'
};

export const OrganizationLevelTypeCode = {
  HO: 'HO',
  COST: 'COST'
};

export const TariffCategoryCode = {
  REP: 'REP'
};

export const WorkflowFormActionTypeCode = {
  STATUS_TRANSITION: 'TRAN_ST',
  CASE_ALLOCATION: 'CS_ALLOC',
  SEND_MAIL: 'SD_MAIL'
};

export const CommentTypeCode = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
  INTERNAL: 'Internal'
};

export const AttachmentTypeCode = {
  RCA: 'RCA',
  VIC: 'VIC',
  ID: 'ID',
  DL: 'DL',
  IMG: 'IMG',
  OTHER: 'OTHER',
  CONTRACT: 'CONTRACT',
  MAIN_VEHICLE_PHOTO: '104',
  MANUAL_UTILIZARE: 'MANUAL_UTILIZARE',
  WORK_ORDER: 'WORK_ORDER',
  HAND_OVER: 'HAND_OVER',
  INVOICE_REPAIR: 'INVOICE_REPAIR',
  LEFT_SIDE_FRONT: '104'
};

export const VehicleAttachmentTypeCodeOrder = ['104','199','105','108','106', '102'];

export const PurchaseOriginCode = {
  NC: '01'
};

export const ParametreCode = {
  TVA: 'TVA',
  CURRENCY: 'CUR'
};
export const CalculationTypeCode = {
  ORG: 'ORG',
  ALT: 'ALT'
};
export const CaseStatusTypeCode = {
  DRAFT: 'DRAFT'
};

export const CarPartQualityTypeCode = {
  OE: 'OE',
  OEM: 'OEM',
  AM: 'AM'
};

export const CarPartQualityRatings = [
  {
    CarPartQualityTypeCode: CarPartQualityTypeCode.OE,
    Rating: 3
  },
  {
    CarPartQualityTypeCode: CarPartQualityTypeCode.OEM,
    Rating: 2
  },
  {
    CarPartQualityTypeCode: CarPartQualityTypeCode.AM,
    Rating: 1
  }
];

export const ReportTypeCode = {
  ESTIMATE_OE: 'DEVIZ_OE',
  RENAULT_OFFER: 'RENAULT_OFFER',
  RENAULT_INVOICE: 'RENAULT_INVOICE',
  RENAULT_CONTRACT: 'RENAULT_CONTRACT',
  ADVERT_REPORT: 'ADVERT_REPORT',
  DEVIZ_AUTOWORLD: 'DEVIZ_AUTOWORLD',
  DEVIZ_CLIENT: 'DEVIZ_CLIENT',
  RENAULT_AVIZ_PLATA: 'RENAULT_AVIZ_PLATA',
  RENAULT_FACTURA_LIVRARE: 'RENAULT_FACTURA_LIVRARE',
  DEVIZ_ALT: 'DEVIZ_ALT',
  FISA_EVALUARE_MASINA: 'FISA_EVALUARE_MASINA',
  WORK_ORDER: 'WORK_ORDER',
  HAND_OVER: 'HAND_OVER',
  DEVIZ_REPARATIE: 'DEVIZ_REPARATIE',
  DEVIZ_ALT_PRC: 'DEVIZ_ALT_PRC',
  DEVIZ_REP_AP: 'DEVIZ_REP_AP'
};

export const InsuredTypeCode = {
  PF: 'PF',
  PJ: 'PJ'
};
export const OrderStatusCode = {
  REP_DRAFT: 'REP_DRAFT',
  REP_LIVRATA: 'REP_LIVRATA',
  REP_PLASATA: 'REP_PLASATA',
  REP_ANULATA: 'REP_ANULATA',
  FZR_IN_LIVRARE: 'FZR_IN_LIVRARE',
  FZR_ACCEPTATA: 'FZR_ACCEPTATA',
  FZR_REFUZATA: 'FZR_REFUZATA',
  FZR_LIVRATA: 'FZR_LIVRATA',
  FZR_COMANDA_CONFIRMATA: 'FZR_COMANDA_CONFIRMATA'
};

export const WorflowEditableReport = {
  FISA_EVALUARE_MASINA: 'FISA_EVALUARE_MASINA',
  PROCES_VERBAL_PREDARE_PRIMIRE: 'PROCES_VERBAL_PREDARE_PRIMIRE'
};
export const OrderPartStatus = {
  UNAVAILABLE: 'NU_DISP',
  PROCESSED: 'PRC',
  PROCESSING: 'IN_PRC',
  UNORDERED: 'NEC',
  ORDERED: 'ACT',
  RETURNED: 'RET',
  OUT_OF_STOCK: 'LIPSA_STOC'
};

export const CalculationSectionCodeEnum = {
  PART: 'PART',
  LABOR: 'LABOR',
  PAINT: 'PAINT',
  PAINT_LABOR: 'PAINT_LABOR'
};

export const OrderStatusTypesCode = {
  FZR: 'FZR_',
  REP: 'REP_'
};

export const OfferTypeCode = {
  SELL: 'SELL',
  BUY: 'BUY'
};

export const CurrencyCode = {
  EUR: 'EUR',
  RON: 'RON',
  USD: 'USD',
  CHF: 'CHF'
};

export const ReferentialCode = {
  OrganizationType: 'OrganizationType',
  OrganizationLevel: 'OrganizationLevel',
  CompanyParameter: 'CompanyParameter',
  Currency: 'Currency',
  Country: 'Country',
  Region: 'Region',
  City: 'City',
  TariffCategory: 'TariffCategory',
  TariffTypeGroup: 'TariffTypeGroup',
  Insurer: 'Insurer',
  CarBrands: 'CarBrands',
  CaseType: 'CaseType',
  NewCaseSteps: 'NewCaseSteps',
  OptimizationType: 'OptimizationType',
  Section: 'Section',
  AttachmentType: 'AttachmentType',
  CaseStatus: 'CaseStatus',
  WorkflowSteps: 'WorkflowStep',
  EmailRecipientType: 'EmailRecipientType',
  CarPartCategory: 'CarPartCategory',
  Report: 'Report',
  ActionType: 'ActionType',
  UserRole: 'UserRole',
  CalculationType: 'CalculationType',
  CarCategory: 'CarCategory',
  CarClass: 'CarClass',
  PolicyType: 'PolicyType',
  PersonType: 'PersonType',
  SolutionValue: 'SolutionValue',
  OrderStatus: 'OrderStatus',
  OrderPartStatus: 'OrderPartStatus',
  FilterType: 'FilterType',
  PaymentType: 'PaymentType',
  PaymentRequestNo: 'PaymentRequestNo',
  VehicleMake: 'Make',
  BodyType: 'BodyType',
  WheelsType: 'WheelsType',
  TractionType: 'TractionType',
  TransmissionType: 'TransmissionType',
  FuelType: 'FuelType',
  TiresType: 'TiresType',
  PaintType: 'PaintType',
  VehicleCondition: 'VehicleCondition',
  PollutionNorm: 'PollutionNorm',
  GuaranteeType: 'GuaranteeType',
  OfferTye: 'OfferType',
  AdvertRegion: 'AdvertRegion',
  AdvertCity: 'AdvertCity',
  QCItemCategory: 'QCItemCategory',
  QCItem: 'QCItem',
  VehicleStatus: 'VehicleStatus',
  Advertiser: 'Advertiser',
  Model: 'Model',
  Color: 'Color',
  AdvertDistrict: 'AdvertDistrict',
  Bank: 'Bank',
  CountryOrigin: 'CountryOrigin',
  Version: 'Version',
  BodyConditionType: 'BodyConditionType',
  WheelConditionType: 'WheelConditionType',
  InvoiceRangeType: 'InvoiceRangeType',
  InvoiceRangeStatus: 'InvoiceRangeStatus',
  FinancialParameter: 'FinancialParameter',
  VehicleType: 'VehicleType',
  MvoDvBattyp: 'MvoDvBattyp',
  PurchaseOrigin: 'PurchaseOrigin',
  SaleChannel: 'SaleChannel',
  MaintenanceOptionalPartName: 'MaintenanceOptionalPartName',
  Refurbished: 'Refurbished',
  VehicleAttachmentType: 'VehicleAttachmentType',
  AlcorParameter: 'AlcorParameter',
  JobType: 'JobType',
  MaintenanceOption: 'MaintenanceOption',
  MaintenancePackage: 'MaintenancePackage',
  OilViscosity: 'OilViscosity',
  CostCenter: 'CostCenter'
};

export const ImportAlternativePartsFileHeader = {
  H1: 'COD_ORIGINALA',
  H2: 'COD_ALTERNATIVA',
  H3: 'NUME_ALTERNATIVA',
  H4: 'PRET_UNITAR_ALTERNATIVA',
  H5: 'CALITATE_ALTERNATIVA',
  H6: 'PRODUCATOR',
  H7: 'FURNIZOR',
  H8: 'TIMP_LIVRARE',
  H9: 'CANTITATE_DISPONIBILA',
  H10: 'LOCATIE_STOC',
  H11: 'OBSERVATII'
};

export const ImportOriginalPartsFileHeader = {
  H1: 'COD_ORIGINALA',
  H2: 'NUME_ORIGINALA',
  H3: 'PRET_UNITAR_ORIGINALA',
  H4: 'CALITATE_ORIGINALA',
  H5: 'UNITATI_ORIGINALA'
};

export const ImportCalculationOriginalPartsFileHeader = {
  H1: 'COD',
  H2: 'NUME',
  H3: 'PRET_UNITAR',
  H4: 'CALITATE',
  H5: 'UNITATI',
  H6: 'DISCOUNT',
  H7: 'FURNIZOR',
  H8: 'TIMP_LIVRARE',
  H9: 'PRODUCATOR',
  H10: 'OBSERVATII'
};

export const ValuationLaguageId = {
  en: 19,
  ro: 55
};

export class Constants {
  public static stsAuthority = process.env.REACT_APP_IDENTITY_URL;
  public static clientId = 'ClaimBoxSPA';
  public static clientRoot = process.env.REACT_APP_APP_ROOT;
  public static clientScope =
    'openid profile ApiGateway TariffService OrganizationService CaseService WorkflowService roles AdministratorClientId_api VehicleService';
  public static apiRoot = process.env.REACT_APP_API_ENDPOINT;
}

export const ClientSettings = {
  authority: Constants.stsAuthority,
  client_id: Constants.clientId,
  redirect_uri: `${Constants.clientRoot}callback`,
  silent_redirect_uri: `${Constants.clientRoot}renewcallback`,
  post_logout_redirect_uri: `${Constants.clientRoot}`,
  response_type: 'code',
  scope: Constants.clientScope,
  automaticSilentRenew: true
};

export const CarDetailsSettings = {
  numberOfKm: 1000000
};

export const VehicleAdvertOperationTypes = {
  publish: 'Publish',
  delete: 'Delete'
};

export const RefConstants = {
  vehicleMakeRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.VehicleMake
  ),
  bodyTypeRef: Referentials.referential.find((item) => item.code === ReferentialCode.BodyType),
  wheelsTypeRef: Referentials.referential.find((item) => item.code === ReferentialCode.WheelsType),
  tractionTypeRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.TractionType
  ),
  transmissionTypeRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.TransmissionType
  ),
  fuelTypeRef: Referentials.referential.find((item) => item.code === ReferentialCode.FuelType),
  tiresTypeRef: Referentials.referential.find((item) => item.code === ReferentialCode.TiresType),
  paintTypeRef: Referentials.referential.find((item) => item.code === ReferentialCode.PaintType),
  vehicleConditionRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.VehicleCondition
  ),
  pollutionNormRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.PollutionNorm
  ),
  modelRef: Referentials.referential.find((item) => item.code === ReferentialCode.Model),
  colorRef: Referentials.referential.find((item) => item.code === ReferentialCode.Color),
  personTypeRef: Referentials.referential.find((item) => item.code === ReferentialCode.PersonType),
  attachementTypeRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.AttachmentType
  ),
  regionRef: Referentials.referential.find((item) => item.code === ReferentialCode.AdvertRegion),
  cityRef: Referentials.referential.find((item) => item.code === ReferentialCode.AdvertCity),
  advertiserRef: Referentials.referential.find((item) => item.code === ReferentialCode.Advertiser),
  districtRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.AdvertDistrict
  ),
  bankRef: Referentials.referential.find((item) => item.code === ReferentialCode.Bank),
  countryOriginRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.CountryOrigin
  ),
  invoiceRangeTypeRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.InvoiceRangeType
  ),
  invoiceRangeStatusRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.InvoiceRangeStatus
  ),
  currencyRef: Referentials.referential.find((item) => item.code === ReferentialCode.Currency),
  financialParameterRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.FinancialParameter
  ),
  vehicleTypeRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.VehicleType
  ),
  mvoDvBattypRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.MvoDvBattyp
  ),
  refurbishedRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.Refurbished
  ),
  versionRef: Referentials.referential.find((item) => item.code === ReferentialCode.Version),
  vehicleAttachmentTypeRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.VehicleAttachmentType
  ),
  alcorParameterRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.AlcorParameter
  ),
  guaranteeTypeRef: Referentials.referential.find(
    (item) => item.code === ReferentialCode.GuaranteeType
  ),
};

export enum VehicleStatusCodeEnum {
  Available = 1,
  Sold = 2,
  Repair = 3,
  Reserved = 4,
  Aquisition = 5,
  AcquisitionRefused = 6,
  ForSale = 7,
  LICITATIE = 8,
  INDISPONIBIL = 9,
  COMANDA = 10,
  AVIZ = 11,
  FACTURAT = 12,
  REZERVAT = 13
}

export enum CaseStatusCodeEnum {
  Closed = 2,
  OF_VANZ = 47,
  OE = 'OE',
  AM = 'AM',
  COM_PI = 'COM_PI',
  PRELUAT_SERVICE = 'PRELUAT_SERVICE',
  SOLICITARE_APROBARE_DEVIZ = 'SOLICITARE_APROBARE_DEVIZ',
  REFUZAT_PRC = 'REFUZAT_PRC',
  COMANDA_ATM = 'COMANDA_ATM',
  INITIATED = 'INITIATED',
  IN_ANALIZA = 'IN_ANALIZA',
  WORK_ORDER_GENERATED = 'WORK_ORDER_GENERATED',
  ACTUALIZARE_DEVIZ = 'ACTUALIZARE_DEVIZ',
  PROGRAMARE_REPARATIE_CONFIRMATA = 'PROGRAMARE_REPARATIE_CONFIRMATA',
  SOLICITA_MODIFICARE_DEVIZ = 'SOLICITA_MODIFICARE_DEVIZ',
  ACTUALIZEAZA_CALCULATIE = 'ACTUALIZEAZA_CALCULATIE'
}

export enum GuaranteeTypeEnum {
  FG = 'FG'
}

export enum CaseTypeCode {
  AQUISITION = 'ACHIZITIE',
  SALE = 'VANZARE',
  CLAIM = 'CLAIM',
  REP = 'REP',
  OFFER = 'OFFER',
  Avizare = 'Avizare',
  OMV = 'OMV'
}

export enum CaseStatusCode {
  DATE_CLIENT = 'DATE_CLIENT'
}

export const allowedVehicleStatuses = new Map([
  [VehicleStatusCodeEnum.Aquisition, []],
  [VehicleStatusCodeEnum.Available, [VehicleStatusCodeEnum.Repair, VehicleStatusCodeEnum.Reserved]],
  [VehicleStatusCodeEnum.ForSale, []],
  [VehicleStatusCodeEnum.Repair, [VehicleStatusCodeEnum.Available]],
  [VehicleStatusCodeEnum.Reserved, [VehicleStatusCodeEnum.Available]],
  [VehicleStatusCodeEnum.Sold, []],
  [VehicleStatusCodeEnum.AcquisitionRefused, []]
]);

export enum SupplierApiType {
  GetParts = 1,
  Comenzi = 2
}

export type Descripted<T> = {
  [K in keyof T]: {
    readonly id: T[K];
    readonly description: string;
  };
}[keyof T];

export function enumToDescriptedArray<T>(enumeration: T): Descripted<T>[] {
  return (Object.keys(enumeration) as Array<keyof T>)
    .filter((key) => isNaN(Number(key)))
    .filter((key) => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string')
    .map((key) => ({
      id: enumeration[key],
      description: String(key)
    }));
}

export const AlternativeCode = {
  TAXAMEDIU: 'TAXAMEDIU'
};

export const SupplierCode = {
  AUGSBURG: 'Augsburg',
  AUTONET: 'Autonet',
  ADTOTAL: 'AD Autototal',
  MATEROM: 'Materom',
  CONEX: 'Conex',
  EUROEST: 'Euroest',
  LKQ: 'LKQ',
  NESTE: 'Neste'
};

export const AdditionalInfoCode = {
  PRODUS_NU_POATE_FI_RETURNAT: 'Acest produs nu poate fi returnat'
};

export const EventDetailsFieldsCode = {
  CASENUMBER: 'caseNumber'
};

export enum UploadSizeLimit {
  SizeLimit = 524288000,
  RenaultSelectionPhotoSizeLimit = 5242880
}

export enum GTErrorCode {
  THE_VIN_NUMBER_SENT_DOES_NOT_DECODE_ANY_MODEL = '9'
}

export enum LanguageCode {
  RO = 'ro',
  EN = 'en'
}

export enum FuelTypeEnum {
  E = 'E'
}

export const allowedMavoVehicleStatuses = new Map([
  [
    VehicleStatusCodeEnum.INDISPONIBIL,
    [
      VehicleStatusCodeEnum.LICITATIE,
      VehicleStatusCodeEnum.COMANDA,
      VehicleStatusCodeEnum.AVIZ,
      VehicleStatusCodeEnum.FACTURAT
    ]
  ],
  [
    VehicleStatusCodeEnum.LICITATIE,
    [
      VehicleStatusCodeEnum.INDISPONIBIL,
      VehicleStatusCodeEnum.COMANDA,
      VehicleStatusCodeEnum.AVIZ,
      VehicleStatusCodeEnum.FACTURAT
    ]
  ],
  [
    VehicleStatusCodeEnum.COMANDA,
    [
      VehicleStatusCodeEnum.INDISPONIBIL,
      VehicleStatusCodeEnum.LICITATIE,
      VehicleStatusCodeEnum.AVIZ,
      VehicleStatusCodeEnum.FACTURAT
    ]
  ],
  [
    VehicleStatusCodeEnum.AVIZ,
    [
      VehicleStatusCodeEnum.INDISPONIBIL,
      VehicleStatusCodeEnum.LICITATIE,
      VehicleStatusCodeEnum.COMANDA,
      VehicleStatusCodeEnum.FACTURAT
    ]
  ],
  [
    VehicleStatusCodeEnum.FACTURAT,
    [
      VehicleStatusCodeEnum.INDISPONIBIL,
      VehicleStatusCodeEnum.LICITATIE,
      VehicleStatusCodeEnum.COMANDA,
      VehicleStatusCodeEnum.AVIZ
    ]
  ]
]);

export enum MavoVehicleStatusCodeEnum {
  INDISPONIBIL = 'INDISPONIBIL',
  LICITATIE = 'LICITATIE',
  COMANDA = 'COMANDA',
  AVIZ = 'AVIZ',
  FACTURAT = 'FACTURAT'
}

export enum MavoVehicleProvenanceType {
  RCI = 'RCI',
  DVS = 'DVS'
}

export const mavoVehiclesForNirEnabled: string[] = [MavoVehicleStatusCodeEnum.INDISPONIBIL];
export const mavoVehicleStatuses = [
  VehicleStatusCodeEnum.INDISPONIBIL,
  VehicleStatusCodeEnum.LICITATIE,
  VehicleStatusCodeEnum.COMANDA,
  VehicleStatusCodeEnum.AVIZ,
  VehicleStatusCodeEnum.FACTURAT
];

export enum MavoVehicelAttachementTypeCodeEnum {
  IMG = 'IMG',
  OTHER = 'OTHER'
}

export enum MavoImageUploadSizeLimit {
  MaxSizeKB = 1024
}

export const disabledNewMavoVehicleStatuses: string[] = [
  MavoVehicleStatusCodeEnum.LICITATIE,
  MavoVehicleStatusCodeEnum.COMANDA,
  MavoVehicleStatusCodeEnum.AVIZ,
  MavoVehicleStatusCodeEnum.FACTURAT
];
export const enabledVinMavoVehicleStatuses: string[] = [MavoVehicleStatusCodeEnum.INDISPONIBIL];
export const disabledMavoVehicleStatuses: string[] = [
  MavoVehicleStatusCodeEnum.COMANDA,
  MavoVehicleStatusCodeEnum.AVIZ,
  MavoVehicleStatusCodeEnum.FACTURAT
];

export enum MakeExceptionEnum {
  FIAT = 'FIAT',
  ALFA = 'ALFA ROMEO',
  LANCIA = 'LANCIA'
}

export const MakeException = [
  MakeExceptionEnum.FIAT.toString(),
  MakeExceptionEnum.ALFA.toString(),
  MakeExceptionEnum.LANCIA.toString()
];
export const RenaultGoldList = ['06', '05', '04'];

export enum MavoPersonTypeEnum {
  PF = 2,
  PJ = 1
}

export enum MavoOrderStatusCodeEnum {
  TRANSMIS = 'TRANSMIS',
  CONFIRMAT = 'CONFIRMAT'
}

export enum MavoVehicleInvoiceStatusEnum {
  VALIDA = 'VALIDA',
  STORNO = 'STORNO',
  STORNATA = 'STORNATA'
}

export const OriginalPartShortNumberException = ['G0600', 'G1401', 'G2205', 'HG060'];

export enum AppointmentStatusCodeEnum {
  CONFIRMED = 'CONFIRMED',
  INITIATA = 'INITIATA',
  RESCHEDULED = 'RESCHEDULED',
  CANCELED = 'CANCELED'
}

export enum InvoiceRangeCodeEnum {
  FACTURA_REPARATIE = 'FACTURA_REPARATIE',
  FACTURA_SERVICE = 'FACTURA_SERVICE'
}

export const renaultMakeCodes = ['RENAULT', 'DACIA'];

export enum TariffCategoryCodeEnum {
  ADAOS = 'ADAOS',
  ADAOS_ASIGURATOR = 'ADAOS_ASIGURATOR',
  ADAOS_ALT_PART = 'ADAOS_ALT_PART'
}

export enum AdvertOptionCodeEnum {
  MAVO = 'MAVO'
}

export enum TariffTypeGroupCodeEnum {
  ADD_PARTS = 'ADD_PARTS',
  PSE_ALT = 'PSE_ALT'
}

export enum RoleCodeEnum {
  ADMIN_COUNTRY_ORGANIZATION = 'ADMIN_COUNTRY_ORGANIZATION'
}

export enum OwnStockCodeEnum {
  OWN_STOCK = 'STOC PROPRIU'
}

export const includedTariffTypeGroupCodes = ['MAN', 'DISC'];

export enum CaseTypeCodeEnum {
  REPARATIE = 'REPARATIE',
  MAINTENANCE = 'REP'
}

export enum CountryCodeEnum {
  CZ = 'CZ',
  RO = 'RO'
}

export enum FilterTypeCodeEnum {
  DS = 'DS'
}

export enum AppointmentCategoryTypeCodeEnum {
  MAINTENANCE = 'MAINTENANCE'
}

// List of codes to exclude
export const excludedCodes = [
  CaseStatusCodeEnum.OE.toString(),
  CaseStatusCodeEnum.AM.toString(),
  CaseStatusCodeEnum.SOLICITA_MODIFICARE_DEVIZ.toString(),
  CaseStatusCodeEnum.IN_ANALIZA.toString(),
  CaseStatusCodeEnum.COM_PI.toString(),
  CaseStatusCodeEnum.ACTUALIZEAZA_CALCULATIE.toString()
];